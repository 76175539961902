import logo from './images/417660871_2088989568152906_3044879732262117442_n.jpg';
import './styles/Home.css';
import { /*Button, */ OverlayTrigger , Tooltip } from 'react-bootstrap';
import DataScientistBadgeDatacamp from "./images/badges/DS_Associate_-_badge_with_outline.png"
import DataAnalystBadgeDatacamp from "./images/badges/DA_Associate_-_badge_with_outline.png"
import DataEngineerBadgeDatacamp from "./images/badges/DE_-_badge_with_outline.png"
import DataLiteracyBadgeDatacamp from "./images/badges/Data_Literacy_-_badge_with_outline.png"

function Home() {
  const renderLinkedinTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <i className='bi-linkedin' style={{color: "#1e88e5"}}/> /zarasvand
    </Tooltip>
  );

  const renderTelegramTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <i className='bi-telegram' style={{color: "#42a5f5"}}/> @zarasvand
    </Tooltip>
  );

  const renderGitlabTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <i className='bi-gitlab' style={{color: "#ffb74d"}}/> /zarasvand
    </Tooltip>
  );

  const renderWhatsappTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <i className='bi-telephone-inbound-fill' style={{color: "#666abb"}}/> &nbsp; <i className='bi-whatsapp' style={{color: "#66bb6a"}}/> +31-687770603
    </Tooltip>
  );

  const renderEmailTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <i className='bi-envelope-at'  style={{color: "#1e88e5"}}/> iam@farzad.eu
    </Tooltip>
  );

  return (
    <div className="App">
      <header className="App-header">
        <div className="stars">
        <i className="bi-star-fill"></i>
        &nbsp;
        <i className="bi-star-fill"></i>
        &nbsp;
        <i className="bi-trophy-fill"></i>
        </div>
        
        <img src={logo} className="App-logo" alt="logo" />

        <h1 className="home-headers">Farzad Zarasvand</h1>
        <h2 className="home-headers">Software Engineer, Data Engineer, MBA</h2>
      </header>
      <div>
        <OverlayTrigger
          placement="bottom"
          html={true}
          delay={{ show: 250, hide: 400 }}
          overlay={renderLinkedinTooltip}
        >
          <button
            title="Linkedin"
            type="button"
            className="btn btn-dark"
            onClick={()=>window.location.href="https://www.linkedin.com/in/zarasvand/"}>
              <i className='bi-linkedin' style={{color: "#1e88e5"}}/>
          </button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          html={true}
          delay={{ show: 250, hide: 400 }}
          overlay={renderGitlabTooltip}
        >
          <button
            type="button"
            className="btn btn-dark"
            onClick={()=>window.location.href="https://gitlab.com/zarasvand"}>
              <i className='bi-gitlab' style={{color: "#ffb74d"}}/>
          </button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          html={true}
          delay={{ show: 250, hide: 400 }}
          overlay={renderWhatsappTooltip}
        >
          <button
            type="button"
            className="btn btn-dark"
            onClick={()=>window.location.href="https://api.whatsapp.com/send/?phone=31687770603&text&type=phone_number&app_absent=0"}>
              <i className='bi-whatsapp' style={{color: "#66bb6a"}}/>
          </button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          html={true}
          delay={{ show: 250, hide: 400 }}
          overlay={renderTelegramTooltip}
        >
          <button
            type="button"
            className="btn btn-dark"
            onClick={()=>window.location.href="https://t.me/zarasvand"}>
              <i className='bi-telegram' style={{color: "#42a5f5"}}/>
          </button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          html={true}
          delay={{ show: 250, hide: 400 }}
          overlay={renderEmailTooltip}
        >
          <button
            type="button"
            className="btn btn-dark"
            onClick={() => window.location.href="mailto:iam@farzad.eu"}>
              <i className='bi-envelope-at'  style={{color: "#FFFACD"}}/>
          </button>
        </OverlayTrigger>
      </div>
      {/* <div style={{paddingTop: 20}}>
        <Button variant="success"><i className='bi-file-earmark-ruled'/> Resume</Button>
        &nbsp;
        <Button variant="primary"><i className='bi-clipboard-data-fill'/> Portfolio</Button>
      </div> */}
      <div style={{paddingTop: 20}}>
        <a target="_blank" rel="noreferrer" href="https://www.datacamp.com/certificate/DE0012552283853"><img src={DataEngineerBadgeDatacamp} className='datacamp-badges' alt="Data Engineer Badge from Datacamp" /></a>
        &nbsp;
        <a target="_blank" rel="noreferrer" href="https://www.datacamp.com/certificate/DSA0015298563257"><img src={DataScientistBadgeDatacamp} className='datacamp-badges' alt="Data Scientist Badge from Datacamp" /></a>
        &nbsp;
        <a target="_blank" rel="noreferrer" href="https://www.datacamp.com/certificate/DAA0017933092658"><img src={DataAnalystBadgeDatacamp} className='datacamp-badges' alt="Data Analyst Badge from Datacamp" /></a>
        &nbsp;
        <a target="_blank" rel="noreferrer" href="https://www.datacamp.com/skill-verification/DL0035169131268"><img src={DataLiteracyBadgeDatacamp} className='datacamp-badges' alt="Data Literacy Badge from Datacamp" /></a>
      </div>
    </div>
  );
}

export default Home;
